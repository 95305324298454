<script setup>
import bs58 from 'bs58'
import store from '../vuex'
import { inject, watch } from 'vue'
import { useSwal } from '@/composables'
import { useRoute } from 'vue-router'
import { useWallet } from 'solana-wallets-vue'
import { Connection, clusterApiUrl, Keypair, SystemProgram, Transaction } from '@solana/web3.js'

const axios = inject('axios')
const swal = useSwal()
const route = useRoute()

async function getBetadrop () {
    const { wallet, connected, publicKey, signMessage, sendTransaction } = useWallet()
    if (!connected.value) {
        swal.fire({
            title: 'Connect Wallet',
            text: 'Connect your Solana wallet cowboy!',
            icon: 'warning',
            confirmButtonText: 'Okay Dokey'
        })
        return
    }
    var success = false
    var sig = Uint8Array.from([])
    const message = 'BETAMAXI: Sign to get the Betadrop!'
    try {
        sig = await signMessage.value(new TextEncoder().encode(message))
        success = true
    } catch (error) {
        console.log(error)
        if (error.message !== 'Transaction rejected') {
            swal.fire({
                title: 'Connect Wallet',
                text: 'Connect your Solana wallet cowboy!',
                icon: 'error',
                confirmButtonText: 'Okay Dokey'
            })
        }
    }
    if (success) {
        const res = await axios.post("https://aidojo.us/api/beta/drop", {
            command: 'get_betadrop',
            wallet: publicKey.value,
            message: message,
            signature: bs58.encode(sig)
        })
        if (res.status === 200) {
            swal.fire({
                title: 'Woof!',
                text: "You're all set to get the Betadrop!",
                icon: 'success',
                confirmButtonText: 'Oh Ya'
            })
        }
    }
}

</script>
<template>
    <div>
        <button class="px-8 m-2 btn bg-gradient-to-r from-[#9945FF] to-[#14F195] hover:from-pink-500 hover:to-yellow-500" style="color: #FFF;" @click="getBetadrop">
            Get The ₿etadrop
        </button>
    </div>
</template>

