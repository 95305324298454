<script setup>
import { ref, inject } from 'vue'
import { useWallet, WalletMultiButton } from 'solana-wallets-vue'
import { useSwal } from '@/composables'
import GetAccessToken from '../components/GetAccessToken'
import store from '../vuex'
import pkg from '../../package.json'

const axios = inject('axios')
const swal = useSwal()
const { PublicKey } = require('@solana/web3.js')
const { readyState } = useWallet()

function secondsUntil(targetDateTime) {
    // Get the current date and time
    let now = new Date();
    // Get the current time zone offset in minutes and convert it to milliseconds
    let currentOffset = now.getTimezoneOffset() * 60 * 1000;
    // Get the time zone offset for Pacific Time (UTC-8 or UTC-7 depending on DST)
    let pacificOffset = new Date().toLocaleString('en-US', { timeZone: 'America/Los_Angeles' });
    pacificOffset = new Date(pacificOffset).getTimezoneOffset() * 60 * 1000;
    // Calculate the target date and time in UTC
    let targetDate = new Date(targetDateTime);
    let targetDateUTC = targetDate.getTime() + pacificOffset - currentOffset;
    // Calculate the current date and time in UTC
    let nowUTC = now.getTime();
    // Calculate the difference in seconds
    let differenceInSeconds = (targetDateUTC - nowUTC) / 1000;
    return Math.round(differenceInSeconds);
}

function generateRandomString(length) {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

const betadrop = ref(secondsUntil('2024-08-29 13:00:00') * 1000)
const participants = ref([
    {
        id: 'beta',
        name: 'BetaAI',
    },
])
const suggest = ['make a meme']
//const suggest = ['tell a joke', 'make a meme', 'my fortune']
const titleImageUrl = ref('https://robohash.org/beta-' + generateRandomString(12) + '.jpg?size=60x60')
const messageList = ref([
    { type: 'text', author: `user1`, data: { text: `Hi. I'm BetaAI.` }, suggestions: suggest }
])
const newMessagesCount = ref(0)
const isChatOpen = ref(false)
const showTypingIndicator = ref('')
const colors = ref({
    header: {
        bg: '#4e8cff',
        text: '#ffffff'
    },
    launcher: {
        bg: '#4e8cff'
    },
    messageList: {
        bg: '#ffffff'
    },
    sentMessage: {
        bg: '#4e8cff',
        text: '#ffffff'
    },
    receivedMessage: {
        bg: '#eaeaea',
        text: '#222222'
    },
    userInput: {
        bg: '#f4f7f9',
        text: '#565867'
    }
})
const alwaysScrollToBottom = ref(false)
const messageStyling = ref(true)

function sendMessage (text) {
    if (text.length > 0) {
        newMessagesCount.value = isChatOpen.value ? newMessagesCount.value : newMessagesCount.value + 1
        // onMessageWasSent({ author: 'support', type: 'text', data: { text } })
    }
}
function onMessageWasSent (message) {
    // called when the user sends a message
    messageList.value = [ ...messageList.value, message ]
    console.log(message)
    var reply = null
    if (message.author === 'me') {
        const text = message.data.text
        if (text === 'tell a joke') {
            reply = { type: 'text', author: `user1`, data: { text: `Joking...` }, suggestions: suggest }
        } else if (text === 'make a meme') {
            reply = { type: 'text', author: `user1`, data: { text: `Memeing...` } }
            axios.post("https://aidojo.us/api/beta/drop", {
                command: 'get_meme',
            }).then((res) => {
                if (res.status === 200) {
                    console.log(res.data.meme)
                    const meme = { type: 'text', author: `user1`, image: res.data.meme, data: { text: ' ' }, suggestions: suggest }
                    messageList.value = [ ...messageList.value, meme ]
                }
            })
        } else {
            reply = { type: 'text', author: `user1`, data: { text: `LOL wut` }, suggestions: suggest }
        }
    }
    if (reply) {
        messageList.value = [ ...messageList.value, reply ]
    }
}
function openChat () {
    // called when the user clicks on the fab button to open the chat
    titleImageUrl.value = 'https://robohash.org/beta-' + generateRandomString(12) + '.jpg?size=60x60'
    isChatOpen.value = true
    newMessagesCount.value = 0
}
function closeChat () {
    // called when the user clicks on the botton to close the chat
    isChatOpen.value = false
}
function handleScrollToTop () {
    // called when the user scrolls message list to top
    // leverage pagination for loading another page of messages
}
function handleOnType () {
    console.log('Emit typing event')
}
function editMessage(message){
    /* const m = messageList.find(m=>m.id === message.id);
    m.isEdited = true;
    m.data.text = message.data.text; */
}

</script>
<style lang="scss">
@mixin keyboard($position: 0, $value: 0) {
    $keys: map-merge((0: '0', 1: '0', 2: '0', 3: '0', 4: '0', 5: '0', 6: '10px', 7: '10px', 8: '10px', 9: '10px'), ($position: $value));
    box-shadow: 15px #{map-get($keys, 0)} 0 var(--key), 30px #{map-get($keys, 1)} 0 var(--key), 45px #{map-get($keys, 2)} 0 var(--key), 60px #{map-get($keys, 3)} 0 var(--key), 75px #{map-get($keys, 4)} 0 var(--key), 90px #{map-get($keys, 5)} 0 var(--key), 22px #{map-get($keys, 6)} 0 var(--key), 37px #{map-get($keys, 7)} 0 var(--key), 52px #{map-get($keys, 8)} 0 var(--key), 60px #{map-get($keys, 8)} 0 var(--key), 68px #{map-get($keys, 8)} 0 var(--key), 83px #{map-get($keys, 9)} 0 var(--key);
}

.typewriter {
    --blue: #5C86FF;
    --blue-dark: #275EFE;
    --key: #fff;
    --paper: #EEF0FD;
    --text: #D3D4EC;
    --tool: #FBC56C;
    --duration: 3s;
    position: relative;
    animation: bounce var(--duration) linear infinite;
    .slide {
        width: 92px;
        height: 20px;
        border-radius: 3px;
        margin-left: 14px;
        transform: translateX(14px);
        background: linear-gradient(var(--blue), var(--blue-dark));
        animation: slide var(--duration) ease infinite;
        &:before,
        &:after,
        i:before {
            content: '';
            position: absolute;
            background: var(--tool);
        }
        &:before {
            width: 2px;
            height: 8px;
            top: 6px;
            left: 100%;
        }
        &:after {
            left: 94px;
            top: 3px;
            height: 14px;
            width: 6px;
            border-radius: 3px;
        }
        i {
            display: block;
            position: absolute;
            right: 100%;
            width: 6px;
            height: 4px;
            top: 4px;
            background: var(--tool);
            &:before {
                right: 100%;
                top: -2px;
                width: 4px;
                border-radius: 2px;
                height: 14px;
            }
        }
    }
    .paper {
        position: absolute;
        left: 24px;
        top: -26px;
        width: 40px;
        height: 46px;
        border-radius: 5px;
        background: var(--paper);
        transform: translateY(46px);
        animation: paper var(--duration) linear infinite;
        &:before {
            content: '';
            position: absolute;
            left: 6px;
            right: 6px;
            top: 7px;
            border-radius: 2px;
            height: 4px;
            transform: scaleY(.8);
            background: var(--text);
            box-shadow: 0 12px 0 var(--text), 0 24px 0 var(--text), 0 36px 0 var(--text);
        }
    }
    .keyboard {
        width: 120px;
        height: 56px;
        margin-top: -10px;
        z-index: 1;
        position: relative;
        &:before,
        &:after {
            content: '';
            position: absolute;
        }
        &:before {
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            border-radius: 7px;
            background: linear-gradient(135deg, var(--blue), var(--blue-dark));
            transform: perspective(10px) rotateX(2deg);
            transform-origin: 50% 100%;
        }
        &:after {
            left: 2px;
            top: 25px;
            width: 11px;
            height: 4px;
            border-radius: 2px;
            @include keyboard;
            animation: keyboard var(--duration) linear infinite;
        }
    }
}

@keyframes bounce {
    85%,
    92%,
    100% {
        transform: translateY(0);
    }
    89% {
        transform: translateY(-4px);
    }
    95% {
        transform: translateY(2px);
    }
}

@keyframes slide {
    5% {
        transform: translateX(14px);
    }
    15%,
    30% {
        transform: translateX(6px);
    }
    40%,
    55% {
        transform: translateX(0);
    }
    65%,
    70% {
        transform: translateX(-4px);
    }
    80%,
    89% {
        transform: translateX(-12px);
    }
    100% {
        transform: translateX(14px);
    }
}

@keyframes paper {
    5% {
        transform: translateY(46px);
    }
    20%,
    30% {
        transform: translateY(34px);
    }
    40%,
    55% {
        transform: translateY(22px);
    }
    65%,
    70% {
        transform: translateY(10px);
    }
    80%,
    85% {
        transform: translateY(0);
    }
    92%,
    100% {
        transform: translateY(46px);
    }
}

@keyframes keyboard {
    5%,
    12%,
    21%,
    30%,
    39%,
    48%,
    57%,
    66%,
    75%,
    84% {
        @include keyboard;
    }
    9% {
        @include keyboard(0, 2px);
    }
    18% {
        @include keyboard(3, 2px);
    }
    27% {
        @include keyboard(6, 12px);
    }
    36% {
        @include keyboard(8, 12px);
    }
    45% {
        @include keyboard(5, 2px);
    }
    54% {
        @include keyboard(1, 2px);
    }
    63% {
        @include keyboard(9, 12px);
    }
    72% {
        @include keyboard(2, 2px);
    }
    81% {
        @include keyboard(7, 12px);
    }
}

.dribbble {
    position: fixed;
    display: block;
    right: 20px;
    bottom: 20px;
    img {
        display: block;
        height: 28px;
    }
}
</style>
<template>
    <div id="page-wrapper">
        <!-- Header -->
        <header id="header">
            <nav id="nav">
                <ul>
                    <li class="mr-5" v-if="readyState === 'Loadable' || readyState === 'NotDetected'"><a @click="disconnect">Disconnect</a></li>
                    <li><wallet-multi-button></wallet-multi-button></li>
                </ul>
            </nav>
        </header>

        <!-- Banner -->
        <section id="banner">
            <div style="display: flex; justify-content: center;">
                <div>
                    <img class="img" src="/images/betadog.png" alt="Betadog" style="max-height: 350px;"/>
                </div>
            </div>
            <div style="display: flex; justify-content: center;">
                <div>
                    <img class="img" src="/images/betadog-name.png" style="max-height: 200px;"/>
                </div>
            </div>
            <div class="inner">
                <p style="margin-bottom: 10px; font-style: italic; font-size: 110%;">
                    <vue-countdown :time="betadrop" v-slot="{ days, hours, minutes, seconds }">
                        <div style="display: flex; align-items: center; justify-content: center;">
                            <div class="grid auto-cols-max grid-flow-col gap-5 text-center">
                                <div class="bg-neutral rounded-box text-neutral-content flex flex-col p-2">
                                    <span class="countdown font-mono text-5xl">
                                        <span :style="'--value:' + days"></span>
                                    </span>
                                    days
                                </div>
                                <div class="bg-neutral rounded-box text-neutral-content flex flex-col p-2">
                                    <span class="countdown font-mono text-5xl">
                                        <span :style="'--value:' + hours"></span>
                                    </span>
                                    hours
                                </div>
                                <div class="bg-neutral rounded-box text-neutral-content flex flex-col p-2">
                                    <span class="countdown font-mono text-5xl">
                                        <span :style="'--value:' + minutes"></span>
                                    </span>
                                    min
                                </div>
                                <div class="bg-neutral rounded-box text-neutral-content flex flex-col p-2">
                                    <span class="countdown font-mono text-5xl">
                                        <span :style="'--value:' + seconds"></span>
                                    </span>
                                    sec
                                </div>
                            </div>
                        </div>
                    </vue-countdown>
                </p>
                <div class="mt-5">
                    <get-access-token></get-access-token>
                </div>
            </div>
        </section>

        <!-- Two -->
        <section id="two" class="wrapper alt style2">
            <section class="spotlight">
                <div class="image"><img src="images/pic01.jpg" alt="" /></div><div class="content">
                    <h2>Who needs Alpha when you can go straight to Beta!</h2>
                    <p class="mt-5">The Beta Verse is where you go to chase the tail… and Beta Dog is the ultimate sniffer.</p>
                    <p class="mt-5">Beta Dog is your gateway to better beta, your guide dog to the Beta&nbsp;Verse.</p>
                    <p class="mt-5"><strong>BUY $BETADOG</strong></p>
                    <div style="margin-top: 5px; font-size: 11px;">BDoGs5RdPco1mCYGBXqYrM3dHiLJYrLjtVHr4ZwqBxx3</div>
                    <div style="display: flex; justify-content: center;">
                        <div class="mt-5">
                            <div style="display: inline; width: 40px; float: left; margin-right: 15px;">
                                <a href="https://www.jup.ag/swap/SOL-BDoGs5RdPco1mCYGBXqYrM3dHiLJYrLjtVHr4ZwqBxx3"><img style="width: 40px; height: 40px;" src="images/jupiter-dex-small.png"/></a>
                            </div>
                            <div style="display: inline; width: 40px; float: left; margin-right: 15px;">
                                <a href="https://raydium.io/swap/?inputMint=EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v&outputMint=BDoGs5RdPco1mCYGBXqYrM3dHiLJYrLjtVHr4ZwqBxx3"><img style="width: 40px; height: 40px;" src="images/raydium-dex-small.png"/></a>
                            </div>
                            <br clear="all"/>
                        </div>
                    </div>
                    <br clear="all"/>
                </div>
            </section>
            <section class="spotlight">
                <div class="image"><img src="images/pic02.jpg" alt="" /></div><div class="content">
                    <h2>Unleash your inner dawg…</h2>
                    <p class="mt-5">
                        Sooo.... do you see Beta Dog as Coin #1 of the Beta&nbsp;Verse?
                    </p>
                </div>
            </section>
            <section class="spotlight">
                <div class="image"><img src="images/pic03.jpg" alt="" /></div><div class="content">
                    <h2>Get More Beta</h2>
                    <p class="mt-5">The cross-roads of technology, decentralization, and dogs.</p>
                </div>
            </section>
        </section>

        <!-- Three -->
        <section id="three" class="wrapper style3 special">
            <div class="inner">
                <header class="major">
                    <h2>Customize your Beta:</h2>
                    <input type="range" min="0" max="100" value="70" class="range range-lg" />
                </header>
                <div class="mockup-window bg-base-300 border">
                    <div class="bg-base-200 flex justify-center px-4 py-16 text-left" style="background-color: #EEEEEE;">
                        <div style="overflow:auto;width:auto;padding:.2em .6em; color: black;">
                            <pre style="margin: 0; line-height: 125%"># Beta code
qsort <span style="color: #333333">=</span> <span style="color: #228899; font-weight: bold">lambda</span> l: l <span style="color: #228899; font-weight: bold">if</span> <span style="color: #007722">len</span>(l) <span style="color: #333333">&lt;=</span> <span style="color: #6666ff; font-weight: bold">1</span>
<span style="color: #228899; font-weight: bold">else</span> qsort([x <span style="color: #228899; font-weight: bold">for</span> x <span style="color: #000000; font-weight: bold">in</span> l[<span style="color: #6666ff; font-weight: bold">1</span>:]
<span style="color: #228899; font-weight: bold">if</span> x <span style="color: #333333">&lt;</span> l[<span style="color: #6666ff; font-weight: bold">0</span>]]) <span style="color: #333333">+</span> [l[<span style="color: #6666ff; font-weight: bold">0</span>]] <span style="color: #333333">+</span>
<span style="color: #228899; font-weight: bold">print</span>(qsort([<span style="color: #6666ff; font-weight: bold">17</span>, <span style="color: #6666ff; font-weight: bold">29</span>, <span style="color: #6666ff; font-weight: bold">11</span>, <span style="color: #6666ff; font-weight: bold">97</span>, <span style="color: #6666ff; font-weight: bold">5</span>]))

<span style="color: red;">BEWARE: this dog is in beta</span>
<span style="color: blue;">

     |\_/|                  
     | @ @   Woof! 
     |   ()              _  
     |  _/\------____ ((| |))
     |               `--' |   
 ____|_       ___|   |___.' 
/_/_____/____/_______|
B3T@DoG
                                </span>
                            </pre>
                        </div>
                    </div>
                </div>
                <ul class="features">
                    <li class="icon fa-paper-plane">
                        <h3>All Your Beta</h3>
                        <p>Are Belong To Us!</p>
                    </li>
                    <li class="icon solid fa-laptop">
                        <h3>Studies Show</h3>
                        <p>βETA &gt; αlpha</p>
                    </li>
                    <li class="icon solid fa-code">
                        <h3>Solana's got that Beta</h3>
                        <p>Solana is <em>still</em> in Beta, don't cha know!</p>
                    </li>
                    <li class="icon solid fa-headphones-alt">
                        <h3>Better Beta</h3>
                        <p>Some Beta is more Beta than others.</p>
                    </li>
                    <li class="icon fa-heart">
                        <h3>Dogs</h3>
                        <p>Run with the big dogs.</p>
                    </li>
                    <li class="icon fa-flag">
                        <h3>The ₿eta Verse</h3>
                        <p>Chill here while they're upgrading the Metaverse.</p>
                    </li>
                </ul>
            </div>
        </section>

        <!-- One -->
        <section id="one" class="wrapper style1 special">
            <div class="inner">
                <header class="major">
                    <h2>Harness Beta Tech</h2>
                </header>
                <div class="mockup-phone border-primary">
                    <div class="camera"></div>
                    <div class="display">
                        <div class="artboard artboard-demo phone-1">
                            <div style="font-size: 24px; padding-top: 5px;">₿ETAPHONE</div>
                            <div style="display: flex; align-items: center; justify-content: center;">
                                <div class="container" style="width: 250px; height: 400px;">
                                    <div id="output" style="overflow: clip; width: 200px;">
                                        <p class="marquee">
                                            <span>(237) 472-8238&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(510) 230-7546&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(200) 472-7342</span>
                                        </p>
                                    </div>
                                    <div class="row">
                                        <div class="digit" id="one">1</div>
                                        <div class="digit" id="two">2
                                            <div class="sub">ABC</div>
                                        </div>
                                        <div class="digit" id="three">3
                                            <div class="sub">DEF</div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="digit" id="four">4
                                            <div class="sub">GHI</div>
                                        </div>
                                        <div class="digit" id="five">5
                                            <div class="sub">JKL</div>
                                        </div>
                                        <div class="digit">6
                                            <div class="sub">MNO</div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="digit">7
                                            <div class="sub">PQRS</div>
                                        </div>
                                        <div class="digit">8
                                            <div class="sub">TUV</div>
                                        </div>
                                        <div class="digit">9
                                            <div class="sub">WXYZ</div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="digit">*
                                        </div>
                                        <div class="digit">0
                                        </div>
                                        <div class="digit">#
                                        </div>
                                    </div>
                                    <div class="botrow">
                                        <div id="call" style="width: 200px;"><i class="fa fa-phone" aria-hidden="true"></i></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style="display: flex; justify-content: center;">
                    <div>
                        <img src="images/betamax.png" alt="" />
                    </div>
                </div>
                <div style="display: flex; justify-content: center;">
                    <div>
                        <p>IT STILL WORKS!</p>
                    </div>
                </div>
            </div>
        </section>


        <section id="cta" class="wrapper style4">
            <div class="inner">
                <header>
                    <h2>Meet the team:</h2>
                    <table>
                        <tr>
                            <td>
                                <img src="/images/midas.png" alt="Midas Avatar" style="border-radius: 50%; height: 100px; width; 100px;"/>
                            </td>
                        </tr>
                        <tr>
                            <td class="text-left pl-10">
                                Midas
                            </td>
                        </tr>
                    </table>
                </header>
                <ul class="actions stacked">
                    <li>Socials:</li>
                    <li><a href="https://x.com/betadogcoin">X BetaDogCoin</a></li>
                    <li><a href="https://t.me/BetaDogCoin">Telegram</a></li>
                    <li><a href="https://linktr.ee/betadog">Linktree</a></li>
                    <li><a href="https://discord.gg/74hX9sK3h2">Discord</a></li>
                    <li>
                        <div style="margin-top: 15px; display: flex; justify-content: center;">
                            <div>
                                <div style="display: inline; width: 40px; float: left; margin-right: 15px;">
                                    <a href="https://www.jup.ag/swap/SOL-BDoGs5RdPco1mCYGBXqYrM3dHiLJYrLjtVHr4ZwqBxx3"><img style="width: 40px; height: 40px;" src="images/jupiter-dex-small.png"/></a>
                                </div>
                                <div style="display: inline; width: 40px; float: left; margin-right: 15px;">
                                    <a href="https://raydium.io/swap/?inputMint=EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v&outputMint=BDoGs5RdPco1mCYGBXqYrM3dHiLJYrLjtVHr4ZwqBxx3"><img style="width: 40px; height: 40px;" src="images/raydium-dex-small.png"/></a>
                                </div>
                                <br clear="all"/>
                            </div>
                        </div>
                        <br clear="all"/>
                    </li>
                    <li>
                        <div style="display: flex; justify-content: center;">
                            <div class="typewriter mt-10">
                                <div class="slide"><i></i></div>
                                <div class="paper"><div style="color: black; margin-left: 10px;">BE</div><div style="color: black; margin-left: 10px;">TA</div></div>
                                <div class="keyboard"></div>
                            </div>
                         </div>
                    </li>
                </ul>
            </div>
        </section>
        <p class="text-center pb-5 mt-20 ml-10 mr-10" style="font-size: 9px; color: gray;">
            &copy; 2024 - AI Dojo, LLC. - Big Dog Dept.<br/>
            The Beta Dog coin offered on this website is a digital collectible intended solely for entertainment purposes. By purchasing Beta Dog coin, you acknowledge and agree that you are buying a digital asset with no intrinsic value and should not expect any financial gains, returns, or investments from this purchase. Duh!
        </p>
        <div>
            <beautiful-chat
                :participants="participants"
                :titleImageUrl="titleImageUrl"
                :onMessageWasSent="onMessageWasSent"
                :messageList="messageList"
                :newMessagesCount="newMessagesCount"
                :isOpen="isChatOpen"
                :close="closeChat"
                :icons="icons"
                :open="openChat"
                :showEmoji="true"
                :showFile="false"
                :showEdition="true"
                :showDeletion="true"
                :deletionConfirmation="true"
                :showTypingIndicator="showTypingIndicator"
                :showLauncher="true"
                :showCloseButton="true"
                :colors="colors"
                :alwaysScrollToBottom="alwaysScrollToBottom"
                :disableUserListToggle="false"
                :messageStyling="messageStyling"
                @onType="handleOnType"
                @edit="editMessage">
                <template v-slot:text-message-body="{ message }">
                    <template v-if="message.image"><img :src="message.image" class="responsive"/></template>
                    <template v-else>{{ message.data.text }}</template>
                </template>
            </beautiful-chat>
        </div>
    </div>
</template>
